import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', '3d7'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '987'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', 'c89'),
        routes: [
          {
            path: '/docs',
            component: ComponentCreator('/docs', 'bb5'),
            routes: [
              {
                path: '/docs/Basics/',
                component: ComponentCreator('/docs/Basics/', 'fa0'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Basics/Working with Images',
                component: ComponentCreator('/docs/Basics/Working with Images', 'ae7'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Basics/Working with Masks',
                component: ComponentCreator('/docs/Basics/Working with Masks', 'b6d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Basics/Working with ROIs',
                component: ComponentCreator('/docs/Basics/Working with ROIs', 'de0'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/',
                component: ComponentCreator('/docs/Features/', '3b1'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/',
                component: ComponentCreator('/docs/Features/Comparison/', '642'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/Addition',
                component: ComponentCreator('/docs/Features/Comparison/Addition', '759'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/Hypotenuse',
                component: ComponentCreator('/docs/Features/Comparison/Hypotenuse', '30b'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/Logical conjunction',
                component: ComponentCreator('/docs/Features/Comparison/Logical conjunction', 'bc7'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/Logical disjunction',
                component: ComponentCreator('/docs/Features/Comparison/Logical disjunction', '11a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Comparison/Subtraction',
                component: ComponentCreator('/docs/Features/Comparison/Subtraction', '411'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/',
                component: ComponentCreator('/docs/Features/Filters/', 'b23'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Blur',
                component: ComponentCreator('/docs/Features/Filters/Blur', '92f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Derivative',
                component: ComponentCreator('/docs/Features/Filters/Derivative', 'e4f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Gaussian Blur',
                component: ComponentCreator('/docs/Features/Filters/Gaussian Blur', '4b5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Gradient',
                component: ComponentCreator('/docs/Features/Filters/Gradient', 'b24'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Grayscale',
                component: ComponentCreator('/docs/Features/Filters/Grayscale', '4c6'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Invert',
                component: ComponentCreator('/docs/Features/Filters/Invert', '65c'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Level',
                component: ComponentCreator('/docs/Features/Filters/Level', '204'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Median',
                component: ComponentCreator('/docs/Features/Filters/Median', '4a7'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Filters/Pixelate',
                component: ComponentCreator('/docs/Features/Filters/Pixelate', '4ff'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/',
                component: ComponentCreator('/docs/Features/Geometry/', '1df'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/Flip',
                component: ComponentCreator('/docs/Features/Geometry/Flip', 'e43'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/Resize',
                component: ComponentCreator('/docs/Features/Geometry/Resize', 'f31'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/Rotate',
                component: ComponentCreator('/docs/Features/Geometry/Rotate', '945'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/Transform',
                component: ComponentCreator('/docs/Features/Geometry/Transform', '3da'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Geometry/Transform and Rotate',
                component: ComponentCreator('/docs/Features/Geometry/Transform and Rotate', '7b1'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/',
                component: ComponentCreator('/docs/Features/Morphology/', '511'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Bottom Hat',
                component: ComponentCreator('/docs/Features/Morphology/Bottom Hat', '461'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Canny Edge Detector',
                component: ComponentCreator('/docs/Features/Morphology/Canny Edge Detector', '5a4'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Closing',
                component: ComponentCreator('/docs/Features/Morphology/Closing', '32c'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Dilation',
                component: ComponentCreator('/docs/Features/Morphology/Dilation', '29e'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Erosion',
                component: ComponentCreator('/docs/Features/Morphology/Erosion', 'd51'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Morphological Gradient',
                component: ComponentCreator('/docs/Features/Morphology/Morphological Gradient', 'b6a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Opening',
                component: ComponentCreator('/docs/Features/Morphology/Opening', 'd17'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Morphology/Top Hat',
                component: ComponentCreator('/docs/Features/Morphology/Top Hat', '31d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/',
                component: ComponentCreator('/docs/Features/Operations/', '3ad'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Clear border',
                component: ComponentCreator('/docs/Features/Operations/Clear border', '71d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Get extrema',
                component: ComponentCreator('/docs/Features/Operations/Get extrema', 'ada'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Paint mask',
                component: ComponentCreator('/docs/Features/Operations/Paint mask', '056'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Remove points',
                component: ComponentCreator('/docs/Features/Operations/Remove points', '1cc'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Threshold',
                component: ComponentCreator('/docs/Features/Operations/Threshold', '19a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Operations/Watershed',
                component: ComponentCreator('/docs/Features/Operations/Watershed', '880'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/',
                component: ComponentCreator('/docs/Features/Regions of interest/', '440'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Centroid',
                component: ComponentCreator('/docs/Features/Regions of interest/Centroid', 'cbb'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Convex Hull',
                component: ComponentCreator('/docs/Features/Regions of interest/Convex Hull', 'a11'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Ellipse',
                component: ComponentCreator('/docs/Features/Regions of interest/Ellipse', 'eb4'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/EQPC',
                component: ComponentCreator('/docs/Features/Regions of interest/EQPC', 'f0d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Feret Diameters',
                component: ComponentCreator('/docs/Features/Regions of interest/Feret Diameters', '406'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Fill ratio',
                component: ComponentCreator('/docs/Features/Regions of interest/Fill ratio', 'a38'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Holes info',
                component: ComponentCreator('/docs/Features/Regions of interest/Holes info', '5f4'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/MBR',
                component: ComponentCreator('/docs/Features/Regions of interest/MBR', '571'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/PED',
                component: ComponentCreator('/docs/Features/Regions of interest/PED', 'b6a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Perimeter',
                component: ComponentCreator('/docs/Features/Regions of interest/Perimeter', 'f46'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Roundness',
                component: ComponentCreator('/docs/Features/Regions of interest/Roundness', 'f86'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Solidity',
                component: ComponentCreator('/docs/Features/Regions of interest/Solidity', 'b3f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Features/Regions of interest/Sphericity',
                component: ComponentCreator('/docs/Features/Regions of interest/Sphericity', '413'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Getting started',
                component: ComponentCreator('/docs/Getting started', '38f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Glossary',
                component: ComponentCreator('/docs/Glossary', '84d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/',
                component: ComponentCreator('/docs/Tutorials/', '4f8'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/Correcting baseline with topHat',
                component: ComponentCreator('/docs/Tutorials/Correcting baseline with topHat', '0dd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/Extracting metadata',
                component: ComponentCreator('/docs/Tutorials/Extracting metadata', 'aaa'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/Image segmentation with threshold',
                component: ComponentCreator('/docs/Tutorials/Image segmentation with threshold', '050'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/Image segmentation with watershed',
                component: ComponentCreator('/docs/Tutorials/Image segmentation with watershed', '32a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/Image stack analysis',
                component: ComponentCreator('/docs/Tutorials/Image stack analysis', 'bbc'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Tutorials/ROI analysis',
                component: ComponentCreator('/docs/Tutorials/ROI analysis', '847'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Useful tips/',
                component: ComponentCreator('/docs/Useful tips/', '9ae'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Useful tips/Blurring techniques and their differences',
                component: ComponentCreator('/docs/Useful tips/Blurring techniques and their differences', '9cd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Useful tips/Out-parameter and its purpose',
                component: ComponentCreator('/docs/Useful tips/Out-parameter and its purpose', '6b3'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Useful tips/Plain call vs method call',
                component: ComponentCreator('/docs/Useful tips/Plain call vs method call', '2fc'),
                exact: true,
                sidebar: "tutorialSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', 'e5f'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
