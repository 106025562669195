export default {
  "025d52b7": [() => import(/* webpackChunkName: "025d52b7" */ "@site/docs/Features/Filters/Pixelate.md"), "@site/docs/Features/Filters/Pixelate.md", require.resolveWeak("@site/docs/Features/Filters/Pixelate.md")],
  "06e2655b": [() => import(/* webpackChunkName: "06e2655b" */ "@site/docs/Features/Filters/Level.md"), "@site/docs/Features/Filters/Level.md", require.resolveWeak("@site/docs/Features/Filters/Level.md")],
  "08eafb15": [() => import(/* webpackChunkName: "08eafb15" */ "@site/docs/Features/Regions of interest/Sphericity.md"), "@site/docs/Features/Regions of interest/Sphericity.md", require.resolveWeak("@site/docs/Features/Regions of interest/Sphericity.md")],
  "0a361b40": [() => import(/* webpackChunkName: "0a361b40" */ "@site/docs/Features/Geometry/Resize.md"), "@site/docs/Features/Geometry/Resize.md", require.resolveWeak("@site/docs/Features/Geometry/Resize.md")],
  "0c4cebef": [() => import(/* webpackChunkName: "0c4cebef" */ "@site/docs/Features/Operations/Clear border.md"), "@site/docs/Features/Operations/Clear border.md", require.resolveWeak("@site/docs/Features/Operations/Clear border.md")],
  "0c6c76a9": [() => import(/* webpackChunkName: "0c6c76a9" */ "@site/docs/Features/Regions of interest/EQPC.md"), "@site/docs/Features/Regions of interest/EQPC.md", require.resolveWeak("@site/docs/Features/Regions of interest/EQPC.md")],
  "0d2b7498": [() => import(/* webpackChunkName: "0d2b7498" */ "@site/docs/Features/Morphology/Top Hat.md"), "@site/docs/Features/Morphology/Top Hat.md", require.resolveWeak("@site/docs/Features/Morphology/Top Hat.md")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "190480fd": [() => import(/* webpackChunkName: "190480fd" */ "@site/docs/Features/Comparison/Addition.md"), "@site/docs/Features/Comparison/Addition.md", require.resolveWeak("@site/docs/Features/Comparison/Addition.md")],
  "1d6a7a18": [() => import(/* webpackChunkName: "1d6a7a18" */ "@site/docs/Features/Morphology/Morphological Gradient.md"), "@site/docs/Features/Morphology/Morphological Gradient.md", require.resolveWeak("@site/docs/Features/Morphology/Morphological Gradient.md")],
  "1d82da59": [() => import(/* webpackChunkName: "1d82da59" */ "@site/docs/Basics/Working with Masks.md"), "@site/docs/Basics/Working with Masks.md", require.resolveWeak("@site/docs/Basics/Working with Masks.md")],
  "1df93b7f": [() => import(/* webpackChunkName: "1df93b7f" */ "@site/src/pages/index.tsx"), "@site/src/pages/index.tsx", require.resolveWeak("@site/src/pages/index.tsx")],
  "1f277ce8": [() => import(/* webpackChunkName: "1f277ce8" */ "@site/docs/Features/Features.md"), "@site/docs/Features/Features.md", require.resolveWeak("@site/docs/Features/Features.md")],
  "1f391b9e": [() => import(/* webpackChunkName: "1f391b9e" */ "@theme/MDXPage"), "@theme/MDXPage", require.resolveWeak("@theme/MDXPage")],
  "21af1bb5": [() => import(/* webpackChunkName: "21af1bb5" */ "@site/docs/Features/Comparison/Logical disjunction.md"), "@site/docs/Features/Comparison/Logical disjunction.md", require.resolveWeak("@site/docs/Features/Comparison/Logical disjunction.md")],
  "2fd708f2": [() => import(/* webpackChunkName: "2fd708f2" */ "@site/docs/Tutorials/Image segmentation with threshold.md"), "@site/docs/Tutorials/Image segmentation with threshold.md", require.resolveWeak("@site/docs/Tutorials/Image segmentation with threshold.md")],
  "3338351e": [() => import(/* webpackChunkName: "3338351e" */ "@site/docs/Features/Operations/Get extrema.md"), "@site/docs/Features/Operations/Get extrema.md", require.resolveWeak("@site/docs/Features/Operations/Get extrema.md")],
  "34c3a6c1": [() => import(/* webpackChunkName: "34c3a6c1" */ "@site/docs/Features/Morphology/Opening.md"), "@site/docs/Features/Morphology/Opening.md", require.resolveWeak("@site/docs/Features/Morphology/Opening.md")],
  "393be207": [() => import(/* webpackChunkName: "393be207" */ "@site/src/pages/markdown-page.md"), "@site/src/pages/markdown-page.md", require.resolveWeak("@site/src/pages/markdown-page.md")],
  "3beb797b": [() => import(/* webpackChunkName: "3beb797b" */ "@site/docs/Features/Filters/Derivative.md"), "@site/docs/Features/Filters/Derivative.md", require.resolveWeak("@site/docs/Features/Filters/Derivative.md")],
  "3df5d568": [() => import(/* webpackChunkName: "3df5d568" */ "@site/docs/Features/Comparison/Subtraction.md"), "@site/docs/Features/Comparison/Subtraction.md", require.resolveWeak("@site/docs/Features/Comparison/Subtraction.md")],
  "3fd0380d": [() => import(/* webpackChunkName: "3fd0380d" */ "@site/docs/Features/Regions of interest/PED.md"), "@site/docs/Features/Regions of interest/PED.md", require.resolveWeak("@site/docs/Features/Regions of interest/PED.md")],
  "405c9165": [() => import(/* webpackChunkName: "405c9165" */ "@site/docs/Features/Regions of interest/Centroid.md"), "@site/docs/Features/Regions of interest/Centroid.md", require.resolveWeak("@site/docs/Features/Regions of interest/Centroid.md")],
  "41047cef": [() => import(/* webpackChunkName: "41047cef" */ "@site/docs/Features/Regions of interest/Roundness.md"), "@site/docs/Features/Regions of interest/Roundness.md", require.resolveWeak("@site/docs/Features/Regions of interest/Roundness.md")],
  "45a38818": [() => import(/* webpackChunkName: "45a38818" */ "@site/docs/Features/Filters/Grayscale.md"), "@site/docs/Features/Filters/Grayscale.md", require.resolveWeak("@site/docs/Features/Filters/Grayscale.md")],
  "46863a17": [() => import(/* webpackChunkName: "46863a17" */ "@site/docs/Features/Comparison/Logical conjunction.md"), "@site/docs/Features/Comparison/Logical conjunction.md", require.resolveWeak("@site/docs/Features/Comparison/Logical conjunction.md")],
  "483d90ef": [() => import(/* webpackChunkName: "483d90ef" */ "@site/docs/Tutorials/Tutorials.md"), "@site/docs/Tutorials/Tutorials.md", require.resolveWeak("@site/docs/Tutorials/Tutorials.md")],
  "49529788": [() => import(/* webpackChunkName: "49529788" */ "@site/docs/Basics/Working with ROIs.md"), "@site/docs/Basics/Working with ROIs.md", require.resolveWeak("@site/docs/Basics/Working with ROIs.md")],
  "4c1fb5f0": [() => import(/* webpackChunkName: "4c1fb5f0" */ "@site/docs/Useful tips/Plain call vs method call.md"), "@site/docs/Useful tips/Plain call vs method call.md", require.resolveWeak("@site/docs/Useful tips/Plain call vs method call.md")],
  "56b8a3ae": [() => import(/* webpackChunkName: "56b8a3ae" */ "@site/docs/Tutorials/ROI analysis.md"), "@site/docs/Tutorials/ROI analysis.md", require.resolveWeak("@site/docs/Tutorials/ROI analysis.md")],
  "5b0d0c06": [() => import(/* webpackChunkName: "5b0d0c06" */ "@site/docs/Features/Morphology/Canny Edge Detector.md"), "@site/docs/Features/Morphology/Canny Edge Detector.md", require.resolveWeak("@site/docs/Features/Morphology/Canny Edge Detector.md")],
  "5b250d6d": [() => import(/* webpackChunkName: "5b250d6d" */ "@site/docs/Features/Operations/Paint mask.md"), "@site/docs/Features/Operations/Paint mask.md", require.resolveWeak("@site/docs/Features/Operations/Paint mask.md")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "607fed4d": [() => import(/* webpackChunkName: "607fed4d" */ "@site/docs/Features/Regions of interest/Solidity.md"), "@site/docs/Features/Regions of interest/Solidity.md", require.resolveWeak("@site/docs/Features/Regions of interest/Solidity.md")],
  "6629ef70": [() => import(/* webpackChunkName: "6629ef70" */ "@site/docs/Basics/Working with Images.md"), "@site/docs/Basics/Working with Images.md", require.resolveWeak("@site/docs/Basics/Working with Images.md")],
  "73c9d738": [() => import(/* webpackChunkName: "73c9d738" */ "@site/docs/Features/Morphology/Closing.md"), "@site/docs/Features/Morphology/Closing.md", require.resolveWeak("@site/docs/Features/Morphology/Closing.md")],
  "74c0b49d": [() => import(/* webpackChunkName: "74c0b49d" */ "@site/docs/Features/Geometry/Flip.md"), "@site/docs/Features/Geometry/Flip.md", require.resolveWeak("@site/docs/Features/Geometry/Flip.md")],
  "7c110bd0": [() => import(/* webpackChunkName: "7c110bd0" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-b5f.json")],
  "80b8e55c": [() => import(/* webpackChunkName: "80b8e55c" */ "@site/docs/Features/Comparison/Hypotenuse.md"), "@site/docs/Features/Comparison/Hypotenuse.md", require.resolveWeak("@site/docs/Features/Comparison/Hypotenuse.md")],
  "816cc5ef": [() => import(/* webpackChunkName: "816cc5ef" */ "@site/docs/Useful tips/Out-parameter and its purpose.md"), "@site/docs/Useful tips/Out-parameter and its purpose.md", require.resolveWeak("@site/docs/Useful tips/Out-parameter and its purpose.md")],
  "849cc956": [() => import(/* webpackChunkName: "849cc956" */ "@site/docs/Features/Regions of interest/Fill ratio.md"), "@site/docs/Features/Regions of interest/Fill ratio.md", require.resolveWeak("@site/docs/Features/Regions of interest/Fill ratio.md")],
  "88f873c9": [() => import(/* webpackChunkName: "88f873c9" */ "@site/docs/Features/Operations/Threshold.md"), "@site/docs/Features/Operations/Threshold.md", require.resolveWeak("@site/docs/Features/Operations/Threshold.md")],
  "958f3099": [() => import(/* webpackChunkName: "958f3099" */ "@site/docs/Features/Operations/Operations.md"), "@site/docs/Features/Operations/Operations.md", require.resolveWeak("@site/docs/Features/Operations/Operations.md")],
  "9b16b258": [() => import(/* webpackChunkName: "9b16b258" */ "@site/docs/Features/Geometry/Geometry.md"), "@site/docs/Features/Geometry/Geometry.md", require.resolveWeak("@site/docs/Features/Geometry/Geometry.md")],
  "9e899a74": [() => import(/* webpackChunkName: "9e899a74" */ "@site/docs/Useful tips/Useful tips.md"), "@site/docs/Useful tips/Useful tips.md", require.resolveWeak("@site/docs/Useful tips/Useful tips.md")],
  "a04f6bee": [() => import(/* webpackChunkName: "a04f6bee" */ "@site/docs/Features/Regions of interest/Ellipse.md"), "@site/docs/Features/Regions of interest/Ellipse.md", require.resolveWeak("@site/docs/Features/Regions of interest/Ellipse.md")],
  "a097d88d": [() => import(/* webpackChunkName: "a097d88d" */ "@site/docs/Features/Regions of interest/Perimeter.md"), "@site/docs/Features/Regions of interest/Perimeter.md", require.resolveWeak("@site/docs/Features/Regions of interest/Perimeter.md")],
  "a11d0fb8": [() => import(/* webpackChunkName: "a11d0fb8" */ "@site/docs/Features/Filters/Median.md"), "@site/docs/Features/Filters/Median.md", require.resolveWeak("@site/docs/Features/Filters/Median.md")],
  "a211111c": [() => import(/* webpackChunkName: "a211111c" */ "@site/docs/Features/Filters/Gaussian Blur.md"), "@site/docs/Features/Filters/Gaussian Blur.md", require.resolveWeak("@site/docs/Features/Filters/Gaussian Blur.md")],
  "a2a792e9": [() => import(/* webpackChunkName: "a2a792e9" */ "@site/docs/Tutorials/Image stack analysis.md"), "@site/docs/Tutorials/Image stack analysis.md", require.resolveWeak("@site/docs/Tutorials/Image stack analysis.md")],
  "a2c238d2": [() => import(/* webpackChunkName: "a2c238d2" */ "@site/docs/Features/Regions of interest/Feret Diameters.md"), "@site/docs/Features/Regions of interest/Feret Diameters.md", require.resolveWeak("@site/docs/Features/Regions of interest/Feret Diameters.md")],
  "a4fd01eb": [() => import(/* webpackChunkName: "a4fd01eb" */ "@site/docs/Tutorials/Extracting metadata.md"), "@site/docs/Tutorials/Extracting metadata.md", require.resolveWeak("@site/docs/Tutorials/Extracting metadata.md")],
  "a7456010": [() => import(/* webpackChunkName: "a7456010" */ "@generated/docusaurus-plugin-content-pages/default/__plugin.json"), "@generated/docusaurus-plugin-content-pages/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-pages/default/__plugin.json")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "aba21aa0": [() => import(/* webpackChunkName: "aba21aa0" */ "@generated/docusaurus-plugin-content-docs/default/__plugin.json"), "@generated/docusaurus-plugin-content-docs/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/__plugin.json")],
  "adb4a592": [() => import(/* webpackChunkName: "adb4a592" */ "@site/docs/Features/Geometry/Rotate.md"), "@site/docs/Features/Geometry/Rotate.md", require.resolveWeak("@site/docs/Features/Geometry/Rotate.md")],
  "b07fe2ee": [() => import(/* webpackChunkName: "b07fe2ee" */ "@site/docs/Features/Regions of interest/Convex Hull.md"), "@site/docs/Features/Regions of interest/Convex Hull.md", require.resolveWeak("@site/docs/Features/Regions of interest/Convex Hull.md")],
  "b2f11c90": [() => import(/* webpackChunkName: "b2f11c90" */ "@site/docs/Tutorials/Image segmentation with watershed.md"), "@site/docs/Tutorials/Image segmentation with watershed.md", require.resolveWeak("@site/docs/Tutorials/Image segmentation with watershed.md")],
  "b37c10f7": [() => import(/* webpackChunkName: "b37c10f7" */ "@site/docs/Features/Regions of interest/Regions of interest.md"), "@site/docs/Features/Regions of interest/Regions of interest.md", require.resolveWeak("@site/docs/Features/Regions of interest/Regions of interest.md")],
  "b5ac8799": [() => import(/* webpackChunkName: "b5ac8799" */ "@site/docs/Glossary.md"), "@site/docs/Glossary.md", require.resolveWeak("@site/docs/Glossary.md")],
  "ba8a31f5": [() => import(/* webpackChunkName: "ba8a31f5" */ "@site/docs/Features/Morphology/Erosion.md"), "@site/docs/Features/Morphology/Erosion.md", require.resolveWeak("@site/docs/Features/Morphology/Erosion.md")],
  "bbc40efd": [() => import(/* webpackChunkName: "bbc40efd" */ "@site/docs/Features/Morphology/Bottom Hat.md"), "@site/docs/Features/Morphology/Bottom Hat.md", require.resolveWeak("@site/docs/Features/Morphology/Bottom Hat.md")],
  "bdb626e0": [() => import(/* webpackChunkName: "bdb626e0" */ "@site/docs/Features/Filters/Gradient.md"), "@site/docs/Features/Filters/Gradient.md", require.resolveWeak("@site/docs/Features/Filters/Gradient.md")],
  "c1d726ad": [() => import(/* webpackChunkName: "c1d726ad" */ "@site/docs/Features/Filters/Invert.md"), "@site/docs/Features/Filters/Invert.md", require.resolveWeak("@site/docs/Features/Filters/Invert.md")],
  "c389692a": [() => import(/* webpackChunkName: "c389692a" */ "@site/docs/Features/Operations/Watershed.md"), "@site/docs/Features/Operations/Watershed.md", require.resolveWeak("@site/docs/Features/Operations/Watershed.md")],
  "c788da81": [() => import(/* webpackChunkName: "c788da81" */ "@site/docs/Tutorials/Correcting baseline with topHat.md"), "@site/docs/Tutorials/Correcting baseline with topHat.md", require.resolveWeak("@site/docs/Tutorials/Correcting baseline with topHat.md")],
  "cb7315f4": [() => import(/* webpackChunkName: "cb7315f4" */ "@site/docs/Basics/Basics.md"), "@site/docs/Basics/Basics.md", require.resolveWeak("@site/docs/Basics/Basics.md")],
  "d1f8ae68": [() => import(/* webpackChunkName: "d1f8ae68" */ "@site/docs/Getting started.md"), "@site/docs/Getting started.md", require.resolveWeak("@site/docs/Getting started.md")],
  "d68c594c": [() => import(/* webpackChunkName: "d68c594c" */ "@site/docs/Features/Geometry/Transform.md"), "@site/docs/Features/Geometry/Transform.md", require.resolveWeak("@site/docs/Features/Geometry/Transform.md")],
  "ddf8b260": [() => import(/* webpackChunkName: "ddf8b260" */ "@site/docs/Features/Morphology/Morphology.md"), "@site/docs/Features/Morphology/Morphology.md", require.resolveWeak("@site/docs/Features/Morphology/Morphology.md")],
  "de01ba95": [() => import(/* webpackChunkName: "de01ba95" */ "@site/docs/Useful tips/Blurring techniques and their differences.md"), "@site/docs/Useful tips/Blurring techniques and their differences.md", require.resolveWeak("@site/docs/Useful tips/Blurring techniques and their differences.md")],
  "e4b836f9": [() => import(/* webpackChunkName: "e4b836f9" */ "@site/docs/Features/Filters/Blur.md"), "@site/docs/Features/Filters/Blur.md", require.resolveWeak("@site/docs/Features/Filters/Blur.md")],
  "e50e4d34": [() => import(/* webpackChunkName: "e50e4d34" */ "@site/docs/Features/Operations/Remove points.md"), "@site/docs/Features/Operations/Remove points.md", require.resolveWeak("@site/docs/Features/Operations/Remove points.md")],
  "e5b75df0": [() => import(/* webpackChunkName: "e5b75df0" */ "@site/docs/Features/Regions of interest/MBR.md"), "@site/docs/Features/Regions of interest/MBR.md", require.resolveWeak("@site/docs/Features/Regions of interest/MBR.md")],
  "e759ef74": [() => import(/* webpackChunkName: "e759ef74" */ "@site/docs/Features/Regions of interest/Holes info.md"), "@site/docs/Features/Regions of interest/Holes info.md", require.resolveWeak("@site/docs/Features/Regions of interest/Holes info.md")],
  "ea009b36": [() => import(/* webpackChunkName: "ea009b36" */ "@site/docs/Features/Geometry/Transform and Rotate.md"), "@site/docs/Features/Geometry/Transform and Rotate.md", require.resolveWeak("@site/docs/Features/Geometry/Transform and Rotate.md")],
  "f0970fce": [() => import(/* webpackChunkName: "f0970fce" */ "@site/docs/Features/Comparison/Comparison.md"), "@site/docs/Features/Comparison/Comparison.md", require.resolveWeak("@site/docs/Features/Comparison/Comparison.md")],
  "f1d69142": [() => import(/* webpackChunkName: "f1d69142" */ "@site/docs/Features/Filters/Filters.md"), "@site/docs/Features/Filters/Filters.md", require.resolveWeak("@site/docs/Features/Filters/Filters.md")],
  "f8e4f9f3": [() => import(/* webpackChunkName: "f8e4f9f3" */ "@site/docs/Features/Morphology/Dilation.md"), "@site/docs/Features/Morphology/Dilation.md", require.resolveWeak("@site/docs/Features/Morphology/Dilation.md")],};
